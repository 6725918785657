@charset "utf-8";

$theme-primary: #6a90aa;
$theme-secondary: #8ed0fb;

@mixin clear {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin mq($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}

@mixin mq-medium {
  @include mq(700px) {
    @content;
  }
}
@mixin mq-large {
  @include mq(1100px) {
    @content;
  }
}

@mixin grid(
  $size: 100%,
  $columns: 12,
  $horizontal-gutter: 0,
  $vertical-gutter: 0
) {
  @include clear;

  max-width: $size;
  margin: 0 auto;

  & > .row {
    @include clear;

    &:last-child {
      @if $vertical-gutter != 0 {
        margin-bottom: -$vertical-gutter;
      }
    }

    & > .column {
      width: 100%;
      float: left;
      margin-bottom: $vertical-gutter;

      @include mq-medium {
        @for $i from 0 through $columns {
          &.size-#{$i} {
            width: ($i / $columns) * 100%;
          }

          &.offset-#{$i} {
            margin-left: ($i / $columns) * 100%;
          }
        }
      }
    }

    @if $horizontal-gutter != 0 {
      margin-left: -$horizontal-gutter;

      & > .column {
        padding-left: $horizontal-gutter;
      }
    }
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #abbfd1;
  padding: 20px 0 0;
  font-family: "Spartan", sans-serif;
  font-size: 13px;
  line-height: 1.5;

  @include mq-medium {
    background: #abbfd1 url("./assets/background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px 10px;
  }
}

input,
button,
select {
  font: inherit;
}

.hero {
  display: block;

  img {
    display: block;
    width: 100%;
  }

  @include mq-medium {
    display: none;
  }
}

.container {
  background: #fff;
  text-align: center;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(#000, 0.2);

  @include mq-medium {
    padding: 40px;
  }
}

.app {
  max-width: 500px;
  margin: 0 auto;
}

header {
  img {
    display: block;
    margin: auto;
    max-width: 60%;
  }
}

input[type="text"],
input[type="email"],
select {
  border: 1px solid #b1b1b1;
  width: 100%;
  display: block;
  padding: 15px 10px;
  outline: none;
  -webkit-appearance: none;
  background: #fff;
  border-radius: 0;
  line-height: 1.2;

  &:focus {
    box-shadow: 0 0 6px #b1e5ff;
  }
}

select {
  background: #fff
    url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjEuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiCiAgIHdpZHRoPSI4cHgiIGhlaWdodD0iNXB4IiB2aWV3Qm94PSIwIDAgOCA1IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDUiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8cGF0aCBmaWxsPSIjMWMxZDFkIiBkPSJNMCwwYzAsMCwzLjQsNC40LDMuNSw0LjRDMy43LDQuNCw3LjEsMCw3LjEsMEgweiIvPgo8L3N2Zz4KCg==")
    no-repeat right 12px center;
}

button,
.button {
  display: block;
  width: 100%;
  color: #fff;
  background: $theme-primary;
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background: $theme-secondary;
  }

  &:disabled {
    background: #AAA;
    cursor: not-allowed;
  }
}

.grid {
  @include grid($horizontal-gutter: 20px);
}

.field {
  margin-bottom: 20px;
  position: relative;
}

.fields {
  text-align: left;

  p {
    margin-bottom: 10px;
  }
}

.copy {
  padding: 0 20px;

  p {
    margin-bottom: 20px;
  }
}

.checkbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  border: 1px solid #b1b1b1;
  vertical-align: baseline;
  margin-right: 6px;
  margin-bottom: -3px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 6px #b1e5ff;
  }

  &.checked {
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+");
    background-size: 75% 75%;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.error-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.6);

  &-content {
    background: #fff;
    box-shadow: 0 2px 8px rgba(#000, 0.3);
    padding: 30px;
    max-width: 420px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;

    ul {
      padding-left: 20px;
    }
  }
}

.modal-close-button {
  position: absolute;
  display: block;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  padding: 0;
  background: none;
  color: #999;
  font-weight: bold;
  font-size: 26px;
  transform: rotate(45deg);

  &:hover {
    background: none;
    color: #666;
  }

  &:before {
    content: "+";
  }
}
